.app {width: 100%;
  display: flex;
  font-family: "Tahoma";
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #212121;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  flex-flow: column wrap;
}
p {
  display: block;
  text-align: center;
}
